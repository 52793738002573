import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import { UploadParticipant } from '../SepiooAPI/SepiooAPIHelper';
import SepiooAPI from '../SepiooAPI/SepiooAPI';

const SettingsModal = ({ isOpen, handleCloseModal, handleSubmit, subscriptionKey, setSubscriptionKey, account, setAccount, apiProxy, setApiProxy }) => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [identifyProgress, setIdentifyProgress] = useState(0);
    const [isIdentifying, setIsIdentifying] = useState(false);
    const [location, setLocation] = useState('');
    const [numberOfDevices, setNumberOfDevices] = useState(200); // Default to 200, adjustable by user


    const handleOpenConfirmDialog = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
        if (!isIdentifying) {
            setIdentifyProgress(0); // Reset progress when closing if not in identifying process
        }
    };

    const handleConfirmIdentifyDisplays = () => {
        setIsIdentifying(true);
        let index = 0;
        const totalDisplays = parseInt(numberOfDevices, 10); // Use the number from state, ensuring it's an integer

        const identifyNextDisplay = () => {
            if (index < totalDisplays) {
                console.log(`Identifying Display: ${index} ${location}`); // Simulate the identifying process
                const title = "";
                const name = "ID:";
                const surname = `${index}`;
                const deviceId = `${index}`;

                const participant = { id: -1, title, name, surname, deviceId };
                UploadParticipant(participant, location);
                index++;
                const progress = ((index / totalDisplays) * 100).toFixed(2);
                setIdentifyProgress(progress);
                setTimeout(identifyNextDisplay, 100); // Adjust the timeout as needed
            } else {
                setIdentifyProgress(100);
                setIsIdentifying(false);
                handleCloseConfirmDialog(); // Optionally close the dialog after completion
            }
        };

        identifyNextDisplay();
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Settings Modal"
                className="modal-overlay"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Account"
                            value={account}
                            onChange={(e) => setAccount(e.target.value)}
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                        <br />
                        <TextField
                            label="Ocp-Apim-Subscription-Key"
                            value={subscriptionKey}
                            onChange={(e) => setSubscriptionKey(e.target.value)}
                            type="password"
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                        <br />
                        <TextField
                            label="API CORS Proxy"
                            value={apiProxy}
                            onChange={(e) => setApiProxy(e.target.value)}
                            type="text"
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                        <Button onClick={handleOpenConfirmDialog} variant="contained" size="medium" style={{ marginBottom: '10px', width: '100%' }}>
                            Identify Displays
                        </Button>
                        <div className="button-container-no-outline">
                            <Button onClick={handleCloseModal}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Dialog open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
                <DialogTitle>{"Confirm Identify Displays"}</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <TextField
                            label="Number of Devices"
                            type="number"
                            value={numberOfDevices}
                            onChange={(e) => setNumberOfDevices(e.target.value)}
                            margin="dense"
                            style={{ flex: 1, marginRight: '16px' }} // Adjust spacing as needed
                        />
                        <div style={{ flex: 1 }}>
                            <SepiooAPI setLocation={setLocation} />
                        </div>
                    </div>
                    <DialogContentText>
                        Are you sure you want to proceed? <br></br>
                        This will overwrite all content displayed on the devices.
                    </DialogContentText>
                    {isIdentifying && (
                        <LinearProgress variant="determinate" value={identifyProgress} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} disabled={isIdentifying}>Cancel</Button>
                    <Button onClick={handleConfirmIdentifyDisplays} autoFocus disabled={isIdentifying || numberOfDevices <= 0}>
                        {isIdentifying ? `Identifying (${identifyProgress}%)` : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default SettingsModal;

import React from 'react';
import Modal from 'react-modal';
import { Button, TextField } from '@mui/material';

function EditEntryModal({ isOpen, handleCloseModal, handleSaveEntry, selectedParticipant, setSelectedParticipant }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Edit Entry Modal"
      className="modal-overlay"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <TextField
          label="Company"
          value={selectedParticipant ? selectedParticipant.title : ''}
          onChange={e => setSelectedParticipant({ ...selectedParticipant, title: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Name"
          value={selectedParticipant ? selectedParticipant.name : ''}
          onChange={e => setSelectedParticipant({ ...selectedParticipant, name: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Surname"
          value={selectedParticipant ? selectedParticipant.surname : ''}
          onChange={e => setSelectedParticipant({ ...selectedParticipant, surname: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Device ID"
          type='number'
          value={selectedParticipant ? selectedParticipant.deviceId : ''}
          onChange={e => setSelectedParticipant({ ...selectedParticipant, deviceId: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <div className="button-container-no-outline">
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSaveEntry}>Save Changes</Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditEntryModal;

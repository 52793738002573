import React, { useState, useEffect, useCallback } from 'react';
import { GetAvailableLocations } from '../SepiooAPI/SepiooAPIHelper'
import { Select, MenuItem, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const SepiooAPI = ({ setLocation }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const fetchLocations = useCallback(() => {
    GetAvailableLocations()
      .then(result => {
        setLocations(result);
        setSelectedLocation(result.length > 0 ? result[0] : "");
        setLocation(result.length > 0 ? result[0] : "");
      })
      .catch(error => console.error('Error fetching locations:', error));
  }, [setLocation]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const handleLocationChange = (event) => {
    console.log(event.target.value);
    setSelectedLocation(event.target.value);
    setLocation(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 80, marginRight: '10px' }}>
        <InputLabel id="demo-simple-select-autowidth-label">Location</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          label="Location"
          id="demo-simple-select-autowidth"
          value={selectedLocation}
          onChange={handleLocationChange}
          style={{ minWidth: '15vw' }} // Set the desired fixed width here
        >
          {locations.map((location, index) => (
            <MenuItem key={index} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SepiooAPI;

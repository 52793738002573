// import { HashRouter } from 'react-router-dom';
import React, { useState } from 'react';
import Modal from 'react-modal';
import FilterableParticipantsTable from './components/FilterableParticipantsTable/FilterableParticipantsTable';
import Header from './components/Header/Header';
import FileUpload from './components/FileUpload/FileUpload';
import SettingsForm from './components/Settings/Settings';
import './App.css';
import { createTheme } from '@mui/material/styles';
import { themeOptions } from './iim_theme.jsx';
import { ThemeProvider } from '@mui/material/styles';
import logo from './logo.png'; // Import the logo

// Create the theme using custom theme options
const theme = createTheme(themeOptions);

Modal.setAppElement('#root'); // Set the root element for accessibility

export default function App() {
  const [participants, setParticipants] = useState([]);


  return (
    <ThemeProvider theme={theme}>

      {/* <HashRouter> */}
        <SettingsForm />
        <Header logo={logo} /> {/* Pass the logo as a prop to the Header component */}


        {participants.length === 0 && (
          <FileUpload setParticipants={setParticipants} />
        )}

        {participants.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            <FilterableParticipantsTable participants={participants} setParticipants={setParticipants} />
          </div>
        )}
      {/* </HashRouter> */}
    </ThemeProvider>
  );
}

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';

function UploadAllDialog({ onOpen, onClose, onConfirm, onCancel, progress, uploading }) {

  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modal-overlay"
    >
      <DialogTitle id="alert-dialog-title">Confirm Upload All Entries</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to upload all entries?
        </DialogContentText>
        {uploading && <LinearProgress variant="determinate" value={progress} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={uploading}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={uploading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadAllDialog;

import React, { useRef } from 'react';
import './FileUpload.css';
import { DropzoneArea } from 'material-ui-dropzone';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 for generating UUIDs

function FileUpload({ setParticipants }) {

  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;

        // Parse CSV content
        const lines = content.split('\n');
        // we have to start from 1 so that the device id corresponds with the excel row number
        let deviceId = 1; // Initialize deviceId

        const newParticipants = lines
          .map((line) => {
            const [title, name, surname] = line.split(',').map((item) => item.trim());
            const newParticipant = { id: uuidv4(), title, name, surname, deviceId }; // Generate UUID for each participant
            deviceId++;
            return newParticipant;
          })
          .filter((participant) => {
            return (
              participant.title !== undefined &&
              participant.name !== undefined &&
              participant.surname !== undefined
            );
          });

        // Update participants state with new data
        setParticipants(newParticipants);
      };

      reader.readAsText(file);
      file = null;
    }
  };

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          height: '100px' // Adjust the height as needed
        }
      }
    }
  });

  return (
    <DropzoneArea
      showPreviewsInDropzone={false}
      acceptedFiles={[
        '.csv',
        'text/csv',
        'application/vnd.ms-excel',
        'application/csv',
        'text/x-csv',
        'application/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values'
      ]}
      filesLimit={1}
      onChange={(files) => handleFileUpload(files[0])}
      dropzoneClass="dropzone" // Set the custom CSS class here
    />

  );
}

export default FileUpload;

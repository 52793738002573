
import { getSubscriptionKeyFromCookie, getAccountFromCookie, getApiProxyFromCookie } from "../Settings/CookieHelper";

import React, { useState } from 'react';
import './Settings.css';
import { IconButton, Fab } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsModal from './SettingsModal'; // Import the SettingsModal component

const SettingsForm = () => {
    const [subscriptionKey, setSubscriptionKey] = useState('');
    const [account, setAccount] = useState('');
    const [apiProxy, setApiProxy] = useState('https://corsproxy.io/?');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal

    // Function to handle opening the modal
    const handleOpenModal = () => {
        // Initialize form fields with data from cookies
        setAccount(getAccountFromCookie());
        setSubscriptionKey(getSubscriptionKeyFromCookie());
        setApiProxy(getApiProxyFromCookie());

        setIsModalOpen(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Save subscription key and account to cookie or perform any other action
        document.cookie = `subscriptionKey=${subscriptionKey}; max-age=${30 * 24 * 60 * 60}; path=/`;
        document.cookie = `account=${account}; max-age=${30 * 24 * 60 * 60}; path=/`;
        document.cookie = `apiProxy=${apiProxy}; max-age=${30 * 24 * 60 * 60}; path=/`;
        handleCloseModal(); // Close the modal after submission
    };

    return (
        <>
            <div style={{ position: 'fixed', bottom: 16, left: 16, zIndex: 10 }}>
                <Fab color="primary" onClick={handleOpenModal}>
                    <SettingsIcon />
                </Fab>
            </div>
 

            <SettingsModal
                isOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                handleSubmit={handleSubmit}
                subscriptionKey={subscriptionKey}
                setSubscriptionKey={setSubscriptionKey}
                account={account}
                setAccount={setAccount}
                apiProxy={apiProxy}
                setApiProxy={setApiProxy}
            />
        </>

    );
};

export default SettingsForm;

import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#FF4A29', // red
    },
    secondary: {
      main: '#FF8C00', // orange
    },
    tertiary: {
      main: '#FFFFFF', // orange
    },
    grey: {
      500: '#8F8F8F', // gray
      200: '#E2E2E2', // light gray
    },
  },
};

import { getSubscriptionKeyFromCookie, getAccountFromCookie, getApiProxyFromCookie } from "../Settings/CookieHelper";

export function GetAvailableLocations() {
    return new Promise((resolve, reject) => {
        const savedSubscriptionKey = getSubscriptionKeyFromCookie();
        const savedAccount = getAccountFromCookie();
        const apiProxy = getApiProxyFromCookie();
        // const apiProxy = 'http://localhost:8080/';

        var myHeaders = new Headers();
        myHeaders.append("Cache-Control", `no-cache`);
        myHeaders.append("Ocp-Apim-Subscription-Key", `${savedSubscriptionKey}`);

        var requestOptions = {
            method: 'GET',
            // redirect: 'follow',
            headers: myHeaders,
        };

        // fetch(`https://cors-anywhere.herokuapp.com/https://industrial-api.azure-api.net/v2.0//${savedAccount}/locations`, requestOptions)
        // fetch(`${apiProxy}https://industrial-api.azure-api.net/v2.0/${savedAccount}/locations`, requestOptions)
        fetch(`${apiProxy}https://industrial-api.azure-api.net/v2.0/${savedAccount}/locations`, requestOptions)
        // fetch(`/api/v2.0/${savedAccount}/locations`, requestOptions)
        .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // return response.json();
                return response.json();
            })
            .then(result => {
                resolve(result); // Resolve with the list of locations
            })
            .catch(error => {
                reject(error); // Reject with the error message
            });
    });
}

function splitAtPercent(str) {
    var [part_1, part_2] = str.split("%", 2);

    if (part_1 == undefined)
        part_1 = ""

    if(part_2 == undefined)
        part_2 = ""

    return [part_1, part_2]
}

export function UploadParticipant(participant, location = "SLFI") {

    const savedSubscriptionKey = getSubscriptionKeyFromCookie();
    const savedAccount = getAccountFromCookie();
    const apiProxy = getApiProxyFromCookie();
    console.log(`Uploading participant: ${participant.deviceId}: ${participant.title} ${participant.name} ${participant.surname} to ${location}`);
    console.log(`Account: ${savedAccount} | Key: ${savedSubscriptionKey}`);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ocp-Apim-Subscription-Key", `${savedSubscriptionKey}`);
    myHeaders.append("Access-Control-Allow-Origin", "http://localhost:3000/");

    const [lastname_line_1, lastname_line_2] = splitAtPercent(participant.surname);

    var raw = JSON.stringify({
        "idFromWebhook": `nametag${participant.deviceId}`,
        "firstname": `${participant.name}`,
        "lastname": `${participant.surname}`,
        "lastname_line_1": lastname_line_1,
        "lastname_line_2": lastname_line_2,
        "company": `${participant.title}`
    });

    console.log(raw);

    var requestOptions = {
        credentials: "same-origin",
        method: 'POST',
        redirect: 'follow',
        headers: myHeaders,
        body: raw,
    };

    // maybe we have to host our own server https://github.com/Rob--W/cors-anywhere/
    // fetch(`https://cors-anywhere.herokuapp.com/https://industrial-api.azure-api.net/v2.0/${savedAccount}/${location}/actions/694b7886-9ea6-4246-9ddf-1ffb138f12a9/trigger`, requestOptions)
    fetch(`${apiProxy}https://industrial-api.azure-api.net/v2.0/${savedAccount}/${location}/actions/694b7886-9ea6-4246-9ddf-1ffb138f12a9/trigger`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    return;
}
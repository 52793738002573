export const getAccountFromCookie = () => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('account='));
    if (cookie) {
        return cookie.split('=')[1];
    }
    return '';
};

export const getSubscriptionKeyFromCookie = () => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('subscriptionKey='));
    if (cookie) {
        return cookie.split('=')[1];
    }
    return '';
};

export const getApiProxyFromCookie = () => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('apiProxy='));
    if (cookie) {
        return cookie.split('=')[1];
    }
    return '';
};

import React, { useState } from 'react';
import './FilterableParticipantsTable.css';
import { UploadParticipant } from '../SepiooAPI/SepiooAPIHelper';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SepiooAPI from '../SepiooAPI/SepiooAPI';
import AddEntryModal from './AddEntryModal';
import EditEntryModal from './EditEntryModal';
import UploadAllDialog from './UploadAllDialog';
import ClearAllDialog from './ClearAllDialog';
import UploadParticipantDialog from './UploadParticipantDialog';
import DeleteParticipantDialog from './DeleteParticipantDialog';


function FilterableParticipantsTable({ participants, setParticipants }) {
  const [isEditEntryModalOpen, setisEditModalOpen] = useState(false);
  const [isAddEntryModalOpen, setIsModalOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [newEntry, setNewEntry] = useState({ id: '', title: '', name: '', surname: '', deviceId: '' });
  const [location, setLocation] = useState('');


  // State variables to manage the visibility of confirmation dialogs
  const [clearAllDialogOpen, setClearAllDialogOpen] = useState(false);
  const [uploadAllDialogOpen, setUploadAllDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedParticipantIdForDelete, setSelectedParticipantIdForDelete] = useState(null);
  const [selectedParticipantIdForUpload, setSelectedParticipantIdForUpload] = useState(null);
  const [uploading, setUploading] = useState(false); // State variable to track uploading status
  const [uploadProgress, setUploadProgress] = useState(0); // State variable to track upload progress

  // Function to handle opening and closing of the "Delete" confirmation dialog
  const handleDeleteDialogOpen = (participantId) => {
    setSelectedParticipantIdForDelete(participantId);
    setDeleteDialogOpen(true);
  };

  // Function to handle the closing of the delete dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Function to handle deletion after confirmation
  const handleDeleteConfirmed = () => {
    handleDeleteParticipant(selectedParticipantIdForDelete);
    handleDeleteDialogClose(); // Close the confirmation dialog
  };

  // Function to handle opening and closing of the "Upload" confirmation dialog
  const handleUploadDialogOpen = (participantId) => {
    setSelectedParticipantIdForUpload(participantId);
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
  };

  // Function to handle uploading after confirmation
  const handleUploadConfirmed = () => {
    handleUploadParticipant(selectedParticipantIdForUpload);
    handleUploadDialogClose(); // Close the confirmation dialog
  };

  // Function to handle opening and closing of the "Clear All" confirmation dialog
  const handleClearAllDialogOpen = () => {
    setClearAllDialogOpen(true);
  };

  const handleClearAllDialogClose = () => {
    setClearAllDialogOpen(false);
  };

  // Function to handle clearing all entries after confirmation
  const handleClearAllConfirmed = () => {
    setParticipants([]);
    handleClearAllDialogClose(); // Close the confirmation dialog
  };

  // Function to handle opening and closing of the "Upload All" confirmation dialog
  const handleUploadAllDialogOpen = () => {
    setUploadAllDialogOpen(true);
  };

  const handleUploadAllDialogClose = () => {
    setUploadAllDialogOpen(false);
  };

  // Function to handle uploading all entries after confirmation
  const handleUploadAllConfirmed = () => {
    let index = 0;
    const totalParticipants = participants.length;

    const uploadNextParticipant = () => {
      if (index < totalParticipants) {
        UploadParticipant(participants[index], location);
        index++;
        const progress = ((index / totalParticipants) * 100).toFixed(2);
        setUploadProgress(progress);
        setTimeout(uploadNextParticipant, 250);
      } else {
        setUploadProgress(100);
        setUploading(false);
        handleUploadAllDialogClose();
      }
    };

    setUploading(true);
    uploadNextParticipant();
  };

  const handleProductInfoChange = (params) => {
    setSelectedParticipant(params.row);
    setisEditModalOpen(true);
  };

  const generateUniqueId = () => {
    // Generate a unique ID using timestamp
    return Date.now().toString();
  };

  const handleDeleteParticipant = (id) => {
    const updatedParticipants = participants.filter(participant => participant.id !== id);
    setParticipants(updatedParticipants);
  };

  const handleUploadParticipant = (id) => {
    const participant = participants.find(p => p.id === id);
    if (participant) {
      UploadParticipant(participant, location);
    }
  };

  const handleCloseModal = () => {
    setisEditModalOpen(false);
    setIsModalOpen(false);
    setSelectedParticipant(null);
  };

  const handleAddEntry = () => {

    if (newEntry.name && newEntry.surname && newEntry.deviceId) {
      if (selectedParticipant) {
        const updatedParticipants = participants.map(participant =>
          participant.id === selectedParticipant.id ? newEntry : participant
        );
        setParticipants(updatedParticipants);
      } else {
        setParticipants(prevParticipants => [...prevParticipants, newEntry]);
      }
      handleCloseModal();
    } else {
      alert('Please fill in all fields.');
    }
  };


  const handleOpenModal = () => {
    console.log("open modal");
    setIsModalOpen(true);

    // Extract existing device IDs
    const existingDeviceIds = participants.map(participant => participant.deviceId);

    // Find the minimum positive integer not in use
    let nextDeviceId = 1;
    while (existingDeviceIds.includes(nextDeviceId)) {
      nextDeviceId++;
    }

    setNewEntry({ id: generateUniqueId(), title: '', name: '', surname: '', deviceId: nextDeviceId });
  };

  const handleSaveEntry = () => {
    // Add functionality to handle adding or editing a participant
    // based on whether selectedParticipant is null or not
    if (selectedParticipant) {
      const updatedParticipants = participants.map(participant =>
        participant.id === selectedParticipant.id ? selectedParticipant : participant
      );
      setParticipants(updatedParticipants);
    } else {
      // Add new participant logic here
    }
    handleCloseModal();
  };

  return (
    <div>
      <div className="button-container">
        <SepiooAPI setLocation={setLocation} />
        <Button onClick={handleOpenModal} variant="contained" size="medium">Add Entry</Button>
        <Button onClick={handleClearAllDialogOpen} variant="contained" size="medium">Clear All Entries</Button>
        <Button onClick={handleUploadAllDialogOpen} variant="contained" size="medium">Upload All</Button>
      </div>

      <DeleteParticipantDialog
        onOpen={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onCancel={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirmed}
      />

      <UploadParticipantDialog
        onOpen={uploadDialogOpen}
        onClose={handleUploadDialogClose}
        onCancel={handleUploadDialogClose}
        onConfirm={handleUploadConfirmed}
      />

      <ClearAllDialog
        onOpen={clearAllDialogOpen}
        onClose={handleClearAllDialogClose}
        onCancel={handleClearAllDialogClose}
        onConfirm={handleClearAllConfirmed}
      />

      <UploadAllDialog
        onOpen={uploadAllDialogOpen}
        onClose={handleUploadAllDialogClose}
        onCancel={handleUploadAllDialogClose}
        onConfirm={handleUploadAllConfirmed}
        progress={uploadProgress}
        uploading={uploading} // Pass uploading state as a prop
      />

      <AddEntryModal
        isOpen={isAddEntryModalOpen}
        handleCloseModal={handleCloseModal}
        handleAddEntry={handleAddEntry}
        setNewEntry={setNewEntry}
        newEntry={newEntry}
      />

      <EditEntryModal
        isOpen={isEditEntryModalOpen}
        handleCloseModal={handleCloseModal}
        handleSaveEntry={handleSaveEntry}
        selectedParticipant={selectedParticipant}
        setSelectedParticipant={setSelectedParticipant}
      />

      <div style={{ flex: 1, width: '100%', marginTop: '10px' }}>
        <DataGrid
          rows={participants}
          columns={[
            { field: 'title', headerName: 'Company', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'surname', headerName: 'Surname', flex: 1 },
            { field: 'deviceId', headerName: 'Device ID', type: 'number', flex: 1 },
            {
              field: 'actions',
              headerName: 'Actions',
              width: 300,
              renderCell: (params) => (
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                  <Button onClick={() => handleProductInfoChange(params)} color="secondary">Edit</Button>
                  <Button onClick={() => handleDeleteDialogOpen(params.row.id)} color="error">Delete</Button>
                  <Button onClick={() => handleUploadDialogOpen(params.row.id)} color="success" >Upload</Button>
                </ButtonGroup>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          pageSize={5}
          paginationMode="client"
          disableRowSelectionOnClick // Make DataGrid not selectable
        />
      </div>
    </div>
  );
}

export default FilterableParticipantsTable;

import React from 'react';
import './Header.css';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography } from '@mui/material';

const Header = ({ logo }) => { // Receive the logo prop
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;


  return (
    <AppBar position="static">
      <Toolbar>
        <img src={logo} alt="Logo" style={{ height: 40, marginRight: 10 }} /> {/* Display the logo */}
        <Typography variant="h6" component="div">
          E-Ink File Uploader
        </Typography>
        {/* You can add more components like buttons, menu items, etc., here */}
      </Toolbar>
    </AppBar>

  );
}

export default Header;
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, TextField } from '@mui/material';

function AddEntryModal({ isOpen, handleCloseModal, handleAddEntry, setNewEntry, newEntry }) {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Add Entry Modal"
      className="modal-overlay"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <TextField
          label="Company"
          value={newEntry.title}
          onChange={e => setNewEntry({ ...newEntry, title: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Name"
          value={newEntry.name}
          onChange={e => setNewEntry({ ...newEntry, name: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Surname"
          value={newEntry.surname}
          onChange={e => setNewEntry({ ...newEntry, surname: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <TextField
          label="Device ID"
          type='number'
          value={newEntry.deviceId}
          onChange={e => setNewEntry({ ...newEntry, deviceId: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <br />
        <div className="button-container-no-outline">
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleAddEntry}>Add Entry</Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddEntryModal;
